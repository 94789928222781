<template>
  <div
    class="summary-icon lighten-5 d-flex justify-center align-center"
    :class="color"
    :style="{ width: `${size}px`, height: `${size}px` }"
  >
    <v-icon
      :color="color"
      class="mr-0 "
      v-bind="$attrs"
      v-html="icon"
      :size="size - 10"
    />
  </div>
</template>

<script>
export default {
  name: "EvaBigIcon",
  props: {
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: "blue",
    },
    size: {
      type: Number,
      default: 26,
    },
  },
}
</script>

<style scoped>
  /*todo : replace with a calculated color in an icon container with :before*/
  .summary-icon {
    border-radius: 50px;
  }
</style>
